// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-book-now-js": () => import("./../../../src/pages/book-now.js" /* webpackChunkName: "component---src-pages-book-now-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-rooms-rates-js": () => import("./../../../src/pages/rooms-rates.js" /* webpackChunkName: "component---src-pages-rooms-rates-js" */),
  "component---src-single-pages-room-single-js": () => import("./../../../src/single-pages/room-single.js" /* webpackChunkName: "component---src-single-pages-room-single-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */)
}

